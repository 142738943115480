import API from "./api";
export class AccountsService extends API {
  constructor() {
    super('admin/accounts');
  }
  async getAccounts({
    orgId,
    page,
    perPage,
    sortColumn,
    sortDirection,
    filterSpec,
    filterText,
    showDetails,
    amcInstanceExists,
    dspAdvertiserExists,
    accountIds
  }) {
    let params = this.getPaginationParams(
      page,
      perPage,
      sortColumn,
      sortDirection,
      filterSpec,
      filterText,
    );
    if (orgId) params.org_id = orgId;
    if (showDetails)
      params.show_detailed_usage = showDetails
    if (accountIds)
      params.account_ids = accountIds
    params.amc_instance_exists = amcInstanceExists
    params.dsp_advertiser_exists = dspAdvertiserExists
    return await this.get({
      params,
    });
  }

  async getAccountDetails(accountId) {
    return await this.get({
      params: { account_id: accountId },
    });
  }

  async configureAccount(body) {
    return await this.post({
      body: body,
    });
  }

  async scheduleRecommendations(accountId, type) { 
    return await this.post({
      body: { account_id: accountId, schedule_type: type },
    })
  }

  async updateAccount(accountId, body={}) {
    return await this.post({
      body: { account_id: accountId, ...body },
    })
  }

  async updateMarketingStream(accountId, enable) {
    let flag
    if (enable) {
      flag = "enable_marketing_stream"
    }
    else {
      flag = "disable_marketing_stream"
    }
    return await this.post({
        body: {account_id: accountId, flag: flag },      
    })
  }

  async refreshMarketingStream(accountId) {
    let flag = "refresh_marketing_stream"
    return await this.post({
        body: {account_id: accountId, flag: flag },      
    })
  }

  async changeOrg(accountId, org) {
    let flag = "change_org"
    return await this.post({
        body: {account_id: accountId, flag: flag, org: org },      
    })
  }

  async updateAdSpendPercentage(accountId, perc) {
    let flag = "update_adspend_percent_on_account"
    return await this.post({
        body: {account_id: accountId, flag: flag, adspend_perc: perc},
    })
  }

  async scheduleImpactAnalysis(accountId, rulesetId, strategyId, days) {
    let flag = "schedule_impact_analysis"
    return await this.post({
        body: {account_id: accountId, flag: flag, ruleset_id: rulesetId, strategy_id: strategyId, days: days},
    })
  }

  async getAmzAccounts(accountId, apiProgram, accessLevel) {
    let flag = "get_amz_accounts"
    return await this.post({
        body: {account_id: accountId, flag: flag, api_program: apiProgram, access_level: accessLevel}
    })
  }

  async greenlightAMCInstance(accountId, email) {
    let flag = "greenlight_amc_instance_linking"
    return await this.post({
        body: {account_id: accountId, flag: flag, email: email}
    })
  }
}


