import React, {useState} from 'react'
import { Box, Button } from '@chakra-ui/react';
import { AccountsService } from 'services/accounts_service';

export default function AMCInstanceGreenLight({account, onComplete}) {

const service = new AccountsService()
const instanceCreated = account.instance_creation_workflow.status == 'AWAITING_MANUAL_LINKING'
const onSubmit = () => {
    service.greenlightAMCInstance(account._id, account.instance_creation_workflow.user_email).then(() => {
        onComplete()
        window.location.reload()
    })
}
  return (<Box>
    <Box>
      {instanceCreated ? `Instance ID: ${account.instance_creation_workflow.instance.instance_id}` : 'No instance created'}

    </Box>
    <Box display="flex" justifyContent="flex-end">
      <Button mt={4} colorScheme='teal' onClick={() => onSubmit()} disabled={!instanceCreated}>Accept</Button>
    </Box>
  </Box>)
}